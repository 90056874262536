import { useEffect, useMemo, FC, useCallback } from 'react';
import { memo } from '../../util/memo';
import { SxProps, useTheme } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import { useDynamic } from '../../hooks/useDynamic';
import { findClosestPersonaAspectRatio } from '../../util/ads/findClosestPersonaAspectRatio';
import Stack from '@mui/material/Stack';
import { useSelectedAddress } from '../../hooks/web3/useSelectedAddress';
import { track } from '@vercel/analytics';
import { useAdBlock } from '../../contexts/AdBlockContext';
import { useAdRefresh } from '../../hooks/ads/useAdRefresh';
import { useDeviceType } from '../../hooks/useDeviceType';

export const MIN_ADS_IMPRESSION_MS = 1000;

export type PersonaAdContainerProps = {
  baseContainerId: string;
  width: number;
  height: number;
  adjustable?: 'width' | 'height';
  sx?: SxProps;
  borderRadius?: string;
};

const PersonaAdContainerUnmemoized: FC<PersonaAdContainerProps> = ({
  baseContainerId,
  width,
  height,
  adjustable,
  sx,
  borderRadius = '0px',
}) => {
  const theme = useTheme();
  const { user } = useAuth();
  const userId = user?.uid;
  const email = user?.email;
  const address = useSelectedAddress();
  const walletAddress = address?.address;
  const personaModule = useDynamic(import('../../config/ads/adConfig'));

  const deviceType = useDeviceType();
  const { spyAd } = useAdBlock();

  const {
    width: adjustedWidth,
    height: adjustedHeight,
    adUnitId,
    aspectRatio,
  } = findClosestPersonaAspectRatio(width, height, adjustable);

  const { containerId, isAlmostVisible } = useAdRefresh({
    baseContainerId,
    aspectRatio,
    adUnitId,
  });

  const environment = process.env.NODE_ENV;

  useEffect(() => {
    const showBanner = async () => {
      if (!personaModule?.adClient) return;
      if (!isAlmostVisible) return;

      const client = personaModule.adClient;
      walletAddress && client.setWalletAddress(walletAddress);
      email && client.setUserEmail(email);
      let spying: string | undefined = undefined;

      await client.showBannerAd(
        {
          adUnitId,
          containerId,
        },
        (errorMessage) => {
          spying = errorMessage;
          console.error(
            'Encountered a Persona error while attempting to showBannerAd:',
            errorMessage,
          );
          track('adLoadFailed', {
            containerId,
            adUnitId,
            errorMessage,
            ...(userId && { userId }),
            environment,
          });
        },
      );
      setTimeout(() => {
        spyAd(spying);
      }, 100);
    };
    showBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAlmostVisible,
    containerId,
    email,
    walletAddress,
    personaModule,
    adUnitId,
    userId,
    environment,
  ]);

  const adClick = useCallback(() => {
    const [location, , , contentId] = containerId.split('-');

    track('adClick', {
      ...(userId && { userId }),
      containerId,
      adUnitId,
      location,
      device: deviceType,
      ...(contentId && { contentId }),
    });
  }, [containerId, adUnitId, userId, deviceType]);

  const memoizedStack = useMemo(() => {
    return (
      <Stack
        id={containerId}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          div: {
            height: '100% !important',
            width: '100% !important',
            maxWidth: 'none !important',
            borderRadius: `${borderRadius} !important`,
          },
          'div > a': {
            height: '100% !important',
            width: '100% !important',
            maxWidth: 'none',
            borderRadius: `${borderRadius} !important`,
          },
          ...sx,
        }}
      />
    );
  }, [borderRadius, containerId, sx]);

  return (
    <Stack
      sx={{
        position: 'relative',
        width: `${adjustedWidth}px !important`,
        height: `${adjustedHeight}px !important`,
        minWidth: `${adjustedWidth}px`,
        minHeight: `${adjustedHeight}px`,
        background: theme.palette.background.lightGradient,
      }}
      key={containerId}
      onClick={adClick}
    >
      {memoizedStack}
    </Stack>
  );
};

export const PersonaAdContainer = memo(PersonaAdContainerUnmemoized);
