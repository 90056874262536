export function shuffle<T>(arr: T[]): T[] {
  const ret: T[] = [];
  for (const e of arr) {
    ret.push(e);
  }
  let currIndex = ret.length;
  let randIndex = 0;

  while (currIndex !== 0) {
    randIndex = Math.floor(Math.random() * currIndex);
    currIndex--;

    const curr = ret[Number(currIndex)];
    ret[Number(currIndex)] = ret[Number(randIndex)];
    ret[Number(randIndex)] = curr;
  }

  return ret;
}

export function randomIntInRange(minimum: number, maximum: number): number {
  return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
}
