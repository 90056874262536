import { ComponentProps, ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { GameCard2 } from '../../cards/GameCard2';
import { HorizontalCarousel } from '../catalogs/HorizontalCarousel';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { GAME_CARD_DIMENSIONS } from '../../../../functions/src/config/ads/adDimensions';
import { renderHits } from '../../../util/algolia/renderHits';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { Game } from '../../../../functions/src/types/firestore/Game';
import { GAME_CAROUSEL_AD } from '../../../../functions/src/util/ads/adConstants';

export const GAME_CARD_HEIGHT = '370px';

export type GameHorizontalCarouselProps = {
  hits: OrNode<Hit<Game<Date>>>[];
  onLoadMore: () => void;
  header?: ReactNode;
};

const GameHorizontalCarouselUnmemoized = ({
  hits,
  onLoadMore,
  header,
}: GameHorizontalCarouselProps) => {
  const Content = useMemo(() => {
    return renderHits(hits, ({ id, title, imgUrl, likes, tags }) => {
      return (
        <GameCard2
          id={id}
          title={title}
          imgUrl={imgUrl}
          likes={likes}
          tags={tags}
        />
      );
    });
  }, [hits]);

  return (
    <HorizontalCarousel
      Content={Content}
      itemWidth={554}
      minHeight={GAME_CARD_HEIGHT}
      header={header}
      onNearEnd={onLoadMore}
    />
  );
};

export const GameHorizontalCarousel = memo(GameHorizontalCarouselUnmemoized);

export const GAME_CAROUSEL_AD_INTERVAL = 20 as const;
export const AD_BORDER_RADIUS = '10px';

export const GameHorizontalCarouselAds = withAdInjection({
  WrappedComponent: GameHorizontalCarousel,
  adInterval: GAME_CAROUSEL_AD_INTERVAL,
  width: GAME_CARD_DIMENSIONS.width,
  height: GAME_CARD_DIMENSIONS.height,
  baseContainerId: GAME_CAROUSEL_AD,
  adjustable: GAME_CARD_DIMENSIONS.adjustable,
  borderRadius: AD_BORDER_RADIUS,
});

export type GameHorizontalCarouselAdsProps = ComponentProps<
  typeof GameHorizontalCarouselAds
>;
