import {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { memo } from '../../util/memo';

export type MessageInputFocusContextProps = {
  isFocused: boolean;
  setFocus: () => void;
  unsetFocus: () => void;
};

export const MessageInputFocusContext = createContext<
  MessageInputFocusContextProps | undefined
>(undefined);

export type MessageInputFocusProviderProps = {
  children: ReactNode;
};

export const useMessageInputFocus = () => {
  const context = useContext(MessageInputFocusContext);
  if (!context) {
    throw new Error(
      'useMessageInputFocus must be used within a MessageInputFocusProvider',
    );
  }
  return context;
};

const MessageInputFocusProviderUnmemoized: FC<
  MessageInputFocusProviderProps
> = ({ children }) => {
  const [isFocused, setIsFocused] = useState(false);

  const setFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const unsetFocus = useCallback(() => {
    setIsFocused(false);
  }, []);

  const memoizedValue = useMemo(() => {
    return { isFocused, unsetFocus, setFocus };
  }, [isFocused, unsetFocus, setFocus]);

  return (
    <MessageInputFocusContext.Provider value={memoizedValue}>
      {children}
    </MessageInputFocusContext.Provider>
  );
};

export const MessageInputFocusProvider = memo(
  MessageInputFocusProviderUnmemoized,
);
