import '../util/supressConsole';
import '../styles/global.css';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CurrencyProvider } from '../contexts/CurrencyContext';
import defaultTheme from '../styles/theme';
import createEmotionCache from '../util/createEmotionCache';
import { AuthProvider } from '../contexts/AuthContext';
// import { TwitchAuthProvider } from 'src/contexts/TwitchAuthContext';
import { GlobalComponentsProvider } from 'src/contexts/GlobalComponentsContext';
import { ServerTimeProvider } from 'src/contexts/useServerTime';
import { LocationProvider } from 'src/contexts/useLocation';
import { PageLoadingProvider } from 'src/contexts/PageLoadingContext';
import { MaintenanceMode } from 'src/components/MaintenanceMode';
import { useUserTimezone } from 'src/hooks/time/useUserTimezone';
import { useRegisterServiceWorker } from 'src/hooks/firebase/useRegisterServiceWorker';
import { MainLayout } from '../components/MainLayout';
import { useDatadog } from '../hooks/useDatadog';
import { Web3Provider } from '../contexts/web3/Web3Context';
import { SignatureRequestProvider } from '../contexts/web3/SignatureRequestContext';
import { ProgressionProvider } from '../contexts/ProgressionContext';
import { IndexingHitsProvider } from '../contexts/algolia/IndexingHitsContext';
import { UserStatusProvider } from '../contexts/UserStatusContext';
import { useEffect } from 'react';
import Head from 'next/head';
import { UnreadMessageCountProvider } from '../contexts/UnreadMessageCountContext';
import { StreamProvider } from '../contexts/get-stream/StreamContext';
import { UrlModificationsProvider } from '../contexts/routing/UrlModificationsContext';
import { NotificationCountProvider } from '../contexts/NotificationCountContext';
import { ActiveChannelGroupProvider } from '../contexts/ActiveChannelGroupContext';
import { RealtimeChannelGroupsProvider } from '../contexts/RealtimeChannelGroupContext';
import { SessionStorageProvider } from '../contexts/SessionStorageContext';
import { LocalStorageProvider } from '../contexts/LocalStorage';
import { Analytics } from '@vercel/analytics/react';
import { OptimisticValueProvider } from '../contexts/OptimisticValueContext';
import { AdBlockProvider } from '../contexts/AdBlockContext';
import { OnlineProvider } from '../contexts/OnlineContext';
import { GlobalIntervalProvider } from '../contexts/GlobalIntervalContext';
import { VerifyEmailProvider } from '../contexts/VerifyEmailContext';
import { PictureInPictureProvider } from '../contexts/PictureInPictureContext';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type BlumintAppProps<T = Record<string, unknown>> = AppProps<T> & {
  emotionCache?: EmotionCache;
};

export const MIN_VIEWPORT_WIDTH = 654;

export default function BlumintApp(props: BlumintAppProps) {
  useRegisterServiceWorker();
  useUserTimezone();
  useDatadog();

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const updateMetaTag = () => {
    const newContent =
      window.screen.width > MIN_VIEWPORT_WIDTH
        ? 'width=device-width, user-scalable=0'
        : `width=${MIN_VIEWPORT_WIDTH}, user-scalable=0`;

    const oldMetaTag = document.querySelector(
      'meta[name="viewport"]',
    ) as HTMLMetaElement | null;
    if (oldMetaTag) {
      if (oldMetaTag.content === newContent) {
        return;
      }
      document.head.removeChild(oldMetaTag);
    }

    const newMetaTag = document.createElement('meta');
    newMetaTag.name = 'viewport';
    newMetaTag.content = newContent;
    document.head.insertBefore(newMetaTag, document.head.firstChild);
  };

  useEffect(() => {
    updateMetaTag();
    window.addEventListener('resize', updateMetaTag);
    return () => {
      window.removeEventListener('resize', updateMetaTag);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptimisticValueProvider>
      <LocalStorageProvider>
        <UrlModificationsProvider>
          <Web3Provider>
            <LocationProvider>
              <ServerTimeProvider>
                {/* <TwitchAuthProvider> */}
                <ThemeProvider theme={defaultTheme}>
                  <CssBaseline />
                  <MaintenanceMode>
                    <PageLoadingProvider>
                      <GlobalIntervalProvider>
                        <AuthProvider>
                          <StreamProvider>
                            <SessionStorageProvider>
                              <OnlineProvider>
                                <AdBlockProvider>
                                  <RealtimeChannelGroupsProvider>
                                    <CurrencyProvider>
                                      <CacheProvider value={emotionCache}>
                                        <UnreadMessageCountProvider>
                                          <ActiveChannelGroupProvider>
                                            <PictureInPictureProvider>
                                              <UserStatusProvider>
                                                <Head>
                                                  <title>BluMint</title>
                                                  <meta
                                                    name="google-adsense-account"
                                                    content="ca-pub-4740369050630888"
                                                  />
                                                  <meta
                                                    name="viewport"
                                                    content={`width=${MIN_VIEWPORT_WIDTH}, user-scalable=0`}
                                                  />
                                                </Head>
                                                <IndexingHitsProvider>
                                                  <NotificationCountProvider>
                                                    <GlobalComponentsProvider>
                                                      <ProgressionProvider>
                                                        <SignatureRequestProvider>
                                                          <VerifyEmailProvider>
                                                            <MainLayout>
                                                              <Component
                                                                {...pageProps}
                                                              />
                                                              <Analytics />
                                                            </MainLayout>
                                                          </VerifyEmailProvider>
                                                        </SignatureRequestProvider>
                                                      </ProgressionProvider>
                                                    </GlobalComponentsProvider>
                                                  </NotificationCountProvider>
                                                </IndexingHitsProvider>
                                              </UserStatusProvider>
                                            </PictureInPictureProvider>
                                          </ActiveChannelGroupProvider>
                                        </UnreadMessageCountProvider>
                                      </CacheProvider>
                                    </CurrencyProvider>
                                  </RealtimeChannelGroupsProvider>
                                </AdBlockProvider>
                              </OnlineProvider>
                            </SessionStorageProvider>
                          </StreamProvider>
                        </AuthProvider>
                      </GlobalIntervalProvider>
                    </PageLoadingProvider>
                  </MaintenanceMode>
                </ThemeProvider>
                {/* </TwitchAuthProvider> */}
              </ServerTimeProvider>
            </LocationProvider>
          </Web3Provider>
        </UrlModificationsProvider>
      </LocalStorageProvider>
    </OptimisticValueProvider>
  );
}
