import Stack from '@mui/material/Stack';
import { EventTime } from 'src/components/leaderboards/EventTime';
import { useMobile } from 'src/hooks/useMobile';
import { useMemo, ReactNode } from 'react';
import { memo } from '../../../../util/memo';
import { TournamentDetail } from 'functions/src/types/firestore/Game/Tournament';
import { useTournamentRegistration } from '../../../../contexts/TournamentRegistrationContext';
import { findIntermissionAround } from '../../../../util/findIntermissionAround';
import { RULES_TRANSLATIONS } from '../../../../util/country';
import { PersonaAdContainer } from '../../../ads/PersonaAdContainer';
import {
  REGISTRATION_DIMENSIONS,
  REGISTRATION_DIMENSIONS_MOBILE,
} from '../../../../../functions/src/config/ads/adDimensions';
import { AccordionInfo } from '../../../AccordionInfo';
import {
  ACCORDION_TITLE_LIVESTREAM,
  AccordionBodyLivestream,
} from '../../../livestream/AccordionBodyLivestream';
import { REGISTRATION_AD } from '../../../../../functions/src/util/ads/adConstants';
import { AD_BORDER_RADIUS } from '../../../algolia/catalog-wrappers/GameHorizontalCarousel';

export const MAX_PANEL_WIDTH = '656px';

export const getTournamentRules = (
  tournamentDetails: TournamentDetail[],
): string | undefined => {
  const detail = tournamentDetails?.find((tournamentDetail) => {
    const { title } = tournamentDetail;
    return RULES_TRANSLATIONS.some((rule) => {
      return title.toLowerCase().includes(rule);
    });
  });
  return detail ? detail.body : undefined;
};

export type RegistrationContainerProps = {
  children?: ReactNode;
  SideComponent?: ReactNode;
};

export const RegistrationContainer = memo(
  function RegistrationContainerUnmemoized({
    children,
    SideComponent,
  }: RegistrationContainerProps) {
    const {
      checkInTime,
      date,
      endDate,
      readyTime,
      phase,
      skipCheckIn,
      intermissions,
      title: tournamentTitle,
      id: tournamentId,
    } = useTournamentRegistration();
    const currentIntermission = intermissions
      ? findIntermissionAround(intermissions)
      : null;
    const isMobile = useMobile();

    const countdownDisplay = useMemo(() => {
      if (currentIntermission) {
        const { end } = currentIntermission;
        return {
          title: 'INTERMISSION UNTIL:',
          date: end,
        };
      }
      switch (phase) {
        case 'unpublished':
        case 'review':
        case 'registration':
          return skipCheckIn
            ? {
                title: 'Starts In:',
                date: date,
              }
            : {
                title: 'Check In Starts:',
                date: checkInTime,
              };
        case 'checkIn':
          return {
            title: 'Check In By:',
            date: readyTime,
          };
        case 'ready':
          return {
            title: 'Starts In:',
            date: date,
          };
        case 'live':
          return {
            title: 'Live now! Ends In:',
            date: endDate,
          };
        case 'finished':
          return {
            title: 'Finished!',
            date: new Date(Date.now()),
          };
        default:
          return {
            title: 'Time Remaining:',
            date: new Date(Date.now()),
          };
      }
    }, [
      currentIntermission,
      phase,
      skipCheckIn,
      date,
      checkInTime,
      readyTime,
      endDate,
    ]);

    const registrationDimensions = isMobile
      ? REGISTRATION_DIMENSIONS_MOBILE
      : REGISTRATION_DIMENSIONS;

    const updatedContainerId = `${REGISTRATION_AD}-${tournamentId}`;

    return (
      <Stack alignItems="center" sx={{ justifyContent: 'center' }}>
        <Stack
          gap={4}
          sx={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}
        >
          <Stack
            gap={4}
            flexDirection="column"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
            width="100%"
          >
            <PersonaAdContainer
              baseContainerId={updatedContainerId}
              width={registrationDimensions.width}
              height={registrationDimensions.height}
              adjustable={registrationDimensions.adjustable}
              borderRadius={AD_BORDER_RADIUS}
            />
            <Stack maxWidth={MAX_PANEL_WIDTH} gap={4}>
              <EventTime {...countdownDisplay} />

              <AccordionInfo
                title={ACCORDION_TITLE_LIVESTREAM}
                body={
                  <AccordionBodyLivestream tournamentTitle={tournamentTitle} />
                }
                openInDialog={false}
              />
              {SideComponent}
            </Stack>
            {!isMobile && children}
          </Stack>
          {isMobile && children}
        </Stack>
      </Stack>
    );
  },
);
