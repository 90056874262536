import { useCallback, useEffect } from 'react';
import { useAuthFlowDialog } from './auth/useAuthFlowDialog';
import { useRouterState } from './routing/useRouterState';
import { AUTH_MAIL_SEND_KEY } from '../contexts/VerifyEmailContext';
import { removeItem } from '../util/webStorage';
import { AUTH_ERROR_LIBRARY } from '../util/auth/authErrorConfig';
import { useErrorAlert } from './useErrorAlert';

export const useActionHandler = () => {
  const { open: openAuthDialog } = useAuthFlowDialog();
  const [mode, setMode] = useRouterState({
    key: 'mode',
    location: 'queryParam',
  });
  const [oobCode, setOobCode] = useRouterState({
    key: 'oobCode',
    location: 'queryParam',
  });
  const { catchError } = useErrorAlert(AUTH_ERROR_LIBRARY);

  const verifyEmail = useCallback(
    async (code: string) => {
      const authImport = import('../config/firebase-client/auth');
      const { auth } = await authImport;
      const firebaseAuthImport = import('firebase/auth');
      const { applyActionCode } = await firebaseAuthImport;
      await applyActionCode(auth, code);
      await auth.currentUser?.reload();
      removeItem(AUTH_MAIL_SEND_KEY);
      openAuthDialog({ entryDialog: 'Verifying Email Success' });
      setMode(undefined);
      setOobCode(undefined);
    },
    [openAuthDialog, setMode, setOobCode],
  );

  useEffect(() => {
    const handler = async () => {
      if (mode === 'verifyEmail' && !!oobCode) {
        await catchError(async () => {
          await verifyEmail(oobCode);
        });
        return;
      }
      if (mode === 'resetPassword') {
        openAuthDialog({ entryDialog: 'Reset Password' });
      }
    };
    handler();
  }, [catchError, mode, oobCode, openAuthDialog, verifyEmail]);
};
