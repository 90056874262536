import { generateNonAdjacentPositions } from '../../../functions/src/util/generateNonAdjacentPositions';

export const unshiftAdPositions = ({
  currentAdPositions,
  numNewElements,
  adInterval,
}: {
  currentAdPositions: Set<number>;
  numNewElements: number;
  adInterval: number;
}) => {
  const newAdPositions = generateNonAdjacentPositions({
    maxIndex: numNewElements - 1,
    numEntities: Math.floor(numNewElements / adInterval),
    allowFirstIndex: true,
  });
  const updatedAdPositions = new Set<number>();

  newAdPositions.forEach((position) => {
    updatedAdPositions.add(position);
  });

  currentAdPositions.forEach((position) => {
    updatedAdPositions.add(position + numNewElements);
  });

  return updatedAdPositions;
};
