import { ELEVATIONS, ELEVATIONS_SOLID } from './elevations';

export const RARITIES = {
  common: 'rgba(204, 226, 255, 0.7)',
  standard: 'rgba(204, 226, 255, 0.7)',
  rare: '#0097A7',
  epic: '#D500F9',
  legendary: '#FFCA28',
  gold: '#F1C305',
  iridescent: '#B367F5',
  basic: '#4DC8FF',
  trace: 'rgba(255,143,0,1)',
  safari: 'rgba(144,114,47,1)',
  caution: 'rgba(192,46,36,1)',
  corsair: 'rgba(228,172,41,1)',
  ice: 'rgba(118,232,255,1)',
  puncher: 'rgba(34,80,110,1)',
  diy: 'rgba(255,182,115,1)',
  toxic: 'rgba(220,69,227,1)',
  strike: 'rgba(250,60,40,1)',
  lava: 'rgba(233,67,38,1)',
  flames: 'rgba(222,98,60,1)',
  justice: 'rgba(194,48,45,1)',
  cards: 'rgba(200,61,61,1)',
  kitty: 'rgba(255,124,180,1)',
  'old glory': 'rgba(7,21,76,1)',
  neon: 'rgba(128,252,252,1)',
  anodized: 'rgba(61,139,135,1)',
  camouflage: 'rgba(171,179,230,1)',
  alert: 'rgba(82,171,181,1)',
  pursuit: 'rgba(161,51,56,1)',
  skull: 'rgba(26,26,44,1)',
  rhyno: 'rgba(0, 255, 150, 1)',
  vandal: 'rgba(16,189,255,1)',
  cleaner: 'rgba(170, 169, 173, 1)',
  explode: 'rgba(255, 215, 0, 1)',
  celt: 'rgba(192, 192, 192, 1)',
  holiday: 'rgba(229, 48, 57, 1)',
  flow: 'rgba(255, 0, 255, 1)',
  chopper: 'rgba(186, 140, 99, 1)',
  memories: 'rgba(0, 105, 148, 1)',
  primal: 'rgba(66, 28, 1, 1)',
  mooncrash: 'rgba(255, 165, 0, 1)',
  steampunk: 'rgba(27, 163, 156, 1)',
  myth: 'rgba(187, 194, 204, 1)',
  octopus: 'rgba(242, 189, 215, 1)',
  hunt: 'rgba(128, 0, 0, 1)',
  desert: 'rgba(177, 166, 136, 1)',
};

export const RARITIES_HOVER = {
  common: 'rgba(204, 226, 255, 0.5)',
  standard: 'rgba(204, 226, 255, 0.5)',
  rare: 'rgba(0, 151, 167, 0.5)',
  epic: 'rgba(213, 0, 249, 0.5)',
  legendary: 'rgba(255, 202, 40, 0.5)',
  gold: 'rgba(241, 195, 5, 0.5)',
  iridescent: 'rgba(179, 103, 245, 0.5)',
  trace: 'rgba(255,143,0,0.5)',
  safari: 'rgba(144,114,47,0.5)',
  caution: 'rgba(192,46,36,0.5)',
  corsair: 'rgba(228,172,41,0.5)',
  ice: 'rgba(118,232,255,0.5)',
  puncher: 'rgba(34,80,110,0.5)',
  diy: 'rgba(255,182,115,0.5)',
  toxic: 'rgba(220,69,227,0.5)',
  strike: 'rgba(250,60,40,0.5)',
  lava: 'rgba(233,67,38,0.5)',
  flames: 'rgba(222,98,60,0.5)',
  justice: 'rgba(194,48,45,0.5)',
  cards: 'rgba(200,61,61,0.5)',
  kitty: 'rgba(183,89,130,0.5)',
  'old glory': 'rgba(7,21,76,0.5)',
  neon: 'rgba(128,252,252,0.5)',
  anodized: 'rgba(61,139,135,0.5)',
  camouflage: 'rgba(171,179,230,0.5)',
  alert: 'rgba(82,171,181,0.5)',
  pursuit: 'rgba(255,80,91,1)',
  skull: 'rgba(26,26,44,0.5)',
  rhyno: 'rgba(0, 255, 150, 0.5)',
  vandal: 'rgba(16,189,255,1)',
  cleaner: 'rgba(170, 169, 173, 0.5)',
  explode: 'rgba(255, 215, 0, 0.5)',
  celt: 'rgba(192, 192, 192, 0.5)',
  holiday: 'rgba(229, 48, 57, 0.5)',
  flow: 'rgba(255, 0, 255, 0.5)',
  chopper: 'rgba(186, 140, 99, 0.5)',
  memories: 'rgba(0, 105, 148, 0.5)',
  primal: 'rgba(66, 28, 1, 0.5)',
  mooncrash: 'rgba(255, 165, 0, 0.5)',
  steampunk: 'rgba(27, 163, 156, 0.5',
  myth: 'rgba(187, 194, 204, 0.5)',
  octopus: 'rgba(242, 189, 215, 0.5)',
  hunt: 'rgba(128, 0, 0, 0.5)',
  desert: 'rgba(177, 166, 136, 0.5)',
};

export const RARITIES_BACKGROUND = {
  disabled:
    'linear-gradient(0deg, rgba(91, 101, 113, 0.5) 0%, rgba(142, 152, 164, 0.4) 90%)',
  epic: 'linear-gradient(180deg, #B86DD2 0%, #7630d1 100%)',
  legendary: 'linear-gradient(180deg, #FFCB44 -13.1%, #FA9808 100%)',
  rare: 'linear-gradient(180deg, #15DBCF 0%, #047150 255.95%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
  common: 'linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)',
  standard: 'linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)',
  gold: 'linear-gradient(180deg, #F1C305 0%, #D1840E 100%)',
  iridescent: 'linear-gradient(180deg, #F9B6CA 0%, #B367F5 100%)',
  basic: 'linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)',
  trace: 'linear-gradient(90deg, #ff8f00 0%, #53bade 100%)',
  safari: 'linear-gradient(90deg, #90722f 0%, #3a3436 100%)',
  caution: 'linear-gradient(90deg, #c02e24 0%, #f2b647 100%)',
  corsair: 'linear-gradient(90deg, #e4ac29 0%, #414141 100%)',
  ice: 'linear-gradient(90deg, #76e8ff 0%, #5aa1ff 100%)',
  puncher: 'linear-gradient(90deg, #22506e 0%, #cdaf56 100%)',
  diy: 'linear-gradient(90deg, #ffb673 0%, #57b7ff 100%)',
  toxic: 'linear-gradient(90deg, #dc45e3 0%, #79ee4b 100%)',
  strike: 'linear-gradient(90deg, #fa3c28 0%, rgba(170,170,170,1) 100%)',
  lava: 'linear-gradient(90deg, rgba(233,67,38,1) 0%, #aaaaaa 100%)',
  flames: 'linear-gradient(90deg, #de623c 0%, #f8dc59 100%)',
  justice: 'linear-gradient(90deg, #c2302d 0%, #131c23 100%)',
  cards: 'linear-gradient(90deg, #c83d3d 0%, #dce3a5 100%)',
  kitty: 'linear-gradient(90deg, #ff7cb4 0%, #d199ff 100%)',
  'old glory': 'linear-gradient(90deg, #07154c 0%, #ffffff 50%, #b02a12 100%)',
  neon: 'linear-gradient(90deg, #80fcfc 0%, #3babff 100%)',
  anodized: 'linear-gradient(90deg, #3d8b87 0%, #272524 100%)',
  camouflage: 'linear-gradient(90deg, #bcc4ff 0%, #6c7d7b 50%, #4c54b4 100%)',
  alert: 'linear-gradient(90deg, #52abb5 0%, #86449e 100%)',
  pursuit: 'linear-gradient(90deg, #ff505b 0%, #a0aab4 100%)',
  skull: 'linear-gradient(90deg, #1a1a2c 0%, #c23f68 100%)',
  rhyno: 'linear-gradient(90deg, #00ff96 0%, #ffffff 100%)',
  vandal: 'linear-gradient(90deg, #10bdff 0%, #8768b0 100%)',
  cleaner: 'linear-gradient(90deg,#aaa9ae 0%, #505c60 100%)',
  explode: 'linear-gradient(90deg,#ffd700 0%, #622f22 100%)',
  celt: 'linear-gradient(90deg,#c0c0c0 0%, #a47449 100%)',
  holiday: 'linear-gradient(90deg,#ffffff 0%, #ffc0cb 50%,#e53039 100%)',
  flow: 'linear-gradient(90deg, #4b0082 0%, #ff00ff 100%)',
  chopper: 'linear-gradient(90deg, #808080 0%, #ba8c63 100%)',
  memories: 'linear-gradient(90deg, #fff000 0%, #006994 100%)',
  primal: 'linear-gradient(90deg, #b5651d 0%, #421c01 100%)',
  mooncrash: 'linear-gradient(90deg, #696969 0%, #ffa500 100%)',
  steampunk: 'linear-gradient(90deg, #c8640a 0%, #1ba39c 100%)',
  myth: 'linear-gradient(90deg, #080808 0%, #bbc2cc 100%)',
  octopus: 'linear-gradient(90deg, #444c38 0%, #f2bdd7 100%)',
  hunt: 'linear-gradient(90deg, #696969 0%,#800000 100%)',
  desert: 'linear-gradient(90deg, #eaddca 0%, #b1a688 100%)',
};

export const PALETTE = {
  // mode: 'dark',
  rarity: RARITIES,
  rarityHover: RARITIES_HOVER,
  rarityBackground: RARITIES_BACKGROUND,
  primary: {
    main: '#4DC8FF',
    light: '#4DC8FF',
    dark: '#0064E9',
    mid: '#2796F4',
    horizontal: 'linear-gradient(90deg, #4DC8FF -33%, #0064E9 200%)',
    horizontalInverse: 'linear-gradient(90deg, #0064E9 -33%, #4DC8FF 200%)',
    vertical: 'linear-gradient(0deg, #0064E9 -33%, #4DC8FF 90%)',
    verticalInverse: 'linear-gradient(180deg, #0064E9 16.67%, #4DC8FF 200%)',
    horizontalLight: 'linear-gradient(90deg, #2EA0F6 0%, #4DC8FF 200%)',
    contrastText: '#fff',
  },
  secondary: {
    main: '#15DBCF',
    light: '#15DBCF',
    dark: '#0DA690',
    mid: '#11C1B0',
    horizontal: 'linear-gradient(90deg, #15DBCF 0%, #0DA690 100%)',
    horizontalInverse: 'linear-gradient(270deg, #15DBCF 0%, #0DA690 100%)',
    vertical: 'linear-gradient(0deg, #0DA690 0%, #15DBCF 90%)',
    verticalInverse: 'linear-gradient(180deg, #0DA690 16.67%, #15DBCF 100%)',
    contrastText: '#fff',
  },
  error: {
    main: '#FE512B',
    light: '#FE512B',
    dark: '#b22222',
    mid: '#D83A27',
    horizontal: 'linear-gradient(90deg, #FE512B 0%, #b22222 200%)',
    horizontalInverse: 'linear-gradient(90deg, #b22222 0%, #FE512B 200%)',
    vertical: 'linear-gradient(0deg, #b22222 -25%, #FE512B 90%)',
    verticalInverse: 'linear-gradient(180deg, #b22222 16.67%, #FE512B 200%)',
    contrastText: '#fff',
  },
  warning: {
    main: '#FFAB31',
    light: '#FFAB31',
    dark: '#ED7103',
    mid: '#F68E1A',
    horizontal: 'linear-gradient(90deg, #FFAB31 -25%, #ED7103 115%)',
    horizontalInverse: 'linear-gradient(90deg, #ED7103 -25%, #FFAB31 115%)',
    vertical: 'linear-gradient(0deg, #ED7103 -25%, #FFAB31 90%)',
    verticalInverse: 'linear-gradient(180deg, #ED7103 16.67%, #FFAB31 200%)',
    contrastText: '#fff',
  },
  info: {
    // Same as primary
    main: '#4DC8FF',
    light: '#4DC8FF',
    dark: '#0064E9',
    mid: '#2796F4',
    horizontal: 'linear-gradient(90deg, #4DC8FF -33%, #0064E9 200%)',
    horizontalInverse: 'linear-gradient(90deg, #0064E9 -33%, #4DC8FF 200%)',
    vertical: 'linear-gradient(0deg, #0064E9 -33%, #4DC8FF 90%)',
    verticalInverse: 'linear-gradient(180deg, #0064E9 16.67%, #4DC8FF 200%)',
    contrastText: '#fff',
  },
  success: {
    main: '#15DBCF',
    light: '#15DBCF',
    dark: '#0DA690',
    mid: '#11C1B0',
    horizontal: 'linear-gradient(90deg, #15DBCF 0%, #0DA690 100%)',
    horizontalInverse: 'linear-gradient(270deg, #15DBCF 0%, #0DA690 100%)',
    vertical: 'linear-gradient(0deg, #0DA690 0%, #15DBCF 90%)',
    verticalInverse: 'linear-gradient(180deg, #0DA690 16.67%, #15DBCF 100%)',
    contrastText: '#fff',
  },
  royal: {
    main: '#C999FF',
    light: '#C999FF',
    dark: '#661AFF',
    mid: '#8E4DFF',
    horizontal: 'linear-gradient(90deg, #C999FF -33%, #661AFF 200%)',
    horizontalInverse: 'linear-gradient(90deg, #661AFF -33%, #C999FF 200%)',
    vertical: 'linear-gradient(0deg, #661AFF -33%, #C999FF 90%)',
    verticalInverse: 'linear-gradient(180deg, #661AFF 16.67%, #C999FF 200%)',
  },
  // primary: {
  //   main: '#4DC8FF',
  //   light: '#4DC8FF',
  //   dark: '#0064E9',
  //   mid: '#2796F4',
  //   horizontal: 'linear-gradient(90deg, #4DC8FF -33%, #0064E9 200%)',
  //   horizontalInverse: 'linear-gradient(90deg, #0064E9 -33%, #4DC8FF 200%)',
  //   vertical: 'linear-gradient(0deg, #0064E9 -33%, #4DC8FF 90%)',
  //   verticalInverse: 'linear-gradient(180deg, #0064E9 16.67%, #4DC8FF 200%)',
  //   contrastText: '#fff',
  // },
  disabled: {
    main: 'rgba(102, 113, 126, 1)',
    vertical: 'linear-gradient(0deg, #66717E 0%, #59566 200%)',
    horizontal: 'linear-gradient(90deg, #66717E 0%, #595b66 200%)',
  },
  text: {
    primary: '#ffffff',
    secondary: 'rgba(204, 226, 255, 0.7)',
    disabled: 'rgba(204, 227, 255, 0.5)',
  },
  background: {
    light: '#001533',
    dark: '#000E21',
    messaging: '#000e21',
    lightGradient:
      'linear-gradient(180deg, rgba(0, 27, 57, 0.80) 14.67%, rgba(0, 27, 59, 0.32) 100%)',
    // default: 'linear-gradient(180deg, #000E21 0%, #001533 100%)',
    elevation: ELEVATIONS,
    elevationSolid: ELEVATIONS_SOLID,
    backgroundGradient: (elevation: string) => {
      return `linear-gradient(${ELEVATIONS_SOLID[`${elevation}`]}, ${
        ELEVATIONS_SOLID[`${elevation}`]
      }),
        'linear-gradient(0deg, #0064E9 -33%, #4DC8FF 90%)'`;
    },
    shades: {
      opacity30: 'rgba(0, 0, 0, 0.30)',
      opacity60: 'rgba(0, 0, 0, 0.6)',
    },
  },
  action: {
    active: '#4DC6FF',
    mainActive: 'linear-gradient(90deg, #4DC6FF 0%, #0065E8 177.78%)',
    hover: 'rgba(128, 183, 255, 0.08)',
    selected: 'rgba(128, 183, 255, 0.16)',
    disabled: 'rgba(128, 183, 255, 0.3)',
    disabledBackground: 'rgba(0, 111, 255, 0.12)',
    focus: 'rgba(128, 183, 255, 0.12)',
    disabledOpaque: '#2D4059',
    opaque: '#80B7FF',
    background: 'rgba(77, 151, 255, 0.1)',
    shades: {
      opacity30: 'rgba(77, 151, 255, 0.1)',
    },
  },
  other: {
    divider: 'linear-gradient(0deg, #0064E9 16.67%, #4DC8FF 200%)', // Same as primary/vertical
    outlinedBorder: 'linear-gradient(0deg, #0064E9 16.67%, #4DC8FF 200%)', // Same as primary/vertical
    filledInputBackground: 'rgba(0, 101, 232, 0.14)',
    standardInputLine:
      'linear-gradient(90deg, rgba(77, 198, 255, 0.7) 16.67%, rgba(0, 101, 232, 0.7) 115%)',
    snackbar: 'rgba(11, 70, 115, 0.8)', // Same as background/elevation.24
    ratingActive: 'linear-gradient(180deg, #FFF64D 0%, #E8A400 100%)',
    textSecondaryShades: {
      opacity8: 'rgba(125, 178, 249, 0.08)',
      opacity12: 'rgba(125, 178, 249, 0.12)',
      opacity16: 'rgba(125, 178, 249, 0.16)',
      opacity30: 'rgba(125, 178, 249, 0.30)',
      opacity50: 'rgba(125, 178, 249, 0.50)',
    },
  },
  scrollbars: {
    primary: {
      '::-webkit-scrollbar': {
        width: '16px',
      },
      '::-webkit-scrollbar-track': {
        background: '#19293e',
        borderRadius: '10px',
        // This border + background-clip achieves the effect of the track being thinner than the thumb
        backgroundClip: 'padding-box',
        border: '6px solid transparent',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)',
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-track-piece:end': {
        background: 'transparent',
        marginBottom: '4px',
      },
      '::-webkit-scrollbar-track-piece:start': {
        background: 'transparent',
        marginTop: '4px',
      },
      '::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
    },
    invisible: {
      '::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    },
  },
};
