export type AdUnitIds = {
  [key: string]: string;
};

const PERSONA_STAGING_AD_UNIT_IDS: AdUnitIds = {
  '600x160': 'cf20c750-2fe4-4761-861f-b73b2247fd4d',
  '160x600': 'e5ae0d72-122f-4605-a54d-8d6498af1be9',
  '300x250': 'bf498e26-eb16-4e35-8954-e65690f28819',
  '970x90': '3a094192-4c7b-4761-a50c-bd9b6a67e987',
  '728x90': '6b76a652-a34d-4c29-8e0b-755253521d0a',
  '320x100': 'bae673ee-aae0-4e84-8c65-df481aa56c19',
};

const PROD_AD_UNIT_IDS: AdUnitIds = {
  '600x160': '51f776ae-1386-485c-9ace-0ee49edc0b7a',
  '160x600': 'bae17079-7b1c-4e65-bac9-5cc84c25a811',
  '300x250': '2eadefdd-ce1f-47cf-b444-ec157b3faf42',
  '970x90': '31c7b507-9e13-4237-af91-3ce5e44e4280',
  '728x90': '4f79d4b6-74e5-4906-98a9-1722d2f9b0fb',
  '321x101': 'ab537d57-5894-4b9f-8cb3-de786f0691cc', //deprecated but seems to still be active
  '320x100': '3c477a15-2bdc-4455-87c6-31a45a7192dd',
};

export const AD_UNIT_IDS =
  process.env.NEXT_PUBLIC_DEPLOYMENT_URL === 'blumint.com'
    ? PROD_AD_UNIT_IDS
    : PERSONA_STAGING_AD_UNIT_IDS;

// const BLU_4651_V2_DEPLOYED: AdUnitIds = {
//   '600x160': '35fd7e34-3e6d-41c2-a9f6-55502953260b',
//   '300x250': 'addd2480-f0a9-4363-85a9-4999990d3a9b',
//   '970x90': '90bfb347-a2ea-403b-ac04-714cf8d6ada9',
//   '321x101': '178a1bff-6099-4f80-b727-5a9c5fd2deb3',
// };
