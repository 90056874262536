import { generateNonAdjacentPositions } from '../../../functions/src/util/generateNonAdjacentPositions';

export const appendAdPositions = ({
  currentAdPositions,
  numNewElements,
  totalElements,
  adInterval,
}: {
  currentAdPositions: Set<number>;
  numNewElements: number;
  totalElements: number;
  adInterval: number;
}) => {
  const expectedAdCount = Math.floor(totalElements / adInterval);
  const currentAdCount = currentAdPositions.size;

  if (expectedAdCount > currentAdCount) {
    const newAdPositions = generateNonAdjacentPositions({
      maxIndex: totalElements - 1,
      numEntities: expectedAdCount - currentAdCount,
      allowFirstIndex: false,
      startIndex: totalElements - numNewElements,
    });

    const updatedAdPositions = new Set(currentAdPositions);
    newAdPositions.forEach((position) => {
      return updatedAdPositions.add(position);
    });

    return updatedAdPositions;
  }

  return currentAdPositions;
};
