import type { StreamChat } from 'stream-chat';
import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { memo } from '../../util/memo';
import { useAuth } from 'src/contexts/AuthContext';
import { toGetStreamId } from '../../../functions/src/util/messaging/mapId';
import { streamChatFrontend } from '../../config/get-stream/streamChatFrontend';
// import type { StreamVideoClient } from '@stream-io/video-react-sdk';

export type StreamContextType = {
  chatClient: StreamChat | null;
  // videoClient: StreamVideoClient | null;
  isLoading: boolean;
};

export type ConnectionStatus = 'Anonymous' | 'None' | 'Authenticated';

export type StreamProviderProps = { children?: ReactNode };

export const StreamContext = createContext<StreamContextType | null>(null);

export const useStream = () => {
  const context = useContext(StreamContext);
  if (!context) {
    throw new Error('useStream must be used within a StreamProvider');
  }
  return context;
};

export const StreamProvider = memo(function StreamProviderUnmemoized({
  children,
}: StreamProviderProps) {
  const { userDataFull } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const [lastConnectionStatus, setLastConnectionStatus] =
    useState<ConnectionStatus>('None');
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  // const [videoClient, setVideoClient] = useState<StreamVideoClient | null>(
  //   null,
  // );

  const chatToken = userDataFull?.hidden?.chatToken;

  useEffect(() => {
    const connect = async (status: ConnectionStatus) => {
      // eslint-disable-next-line no-shadow
      // const { StreamVideoClient } = await import('@stream-io/video-react-sdk');

      if (!userDataFull) {
        return;
      }
      const userId = toGetStreamId(userDataFull.id);

      if (status === 'Anonymous' || userDataFull.isAnonymous) {
        await streamChatFrontend.setGuestUser({ id: userId });
      } else {
        const userGetStream = {
          id: userId,
          name: userDataFull.username,
          image: userDataFull.imgUrl,
        };
        await streamChatFrontend.connectUser(userGetStream, chatToken);
        // setVideoClient(
        //   new StreamVideoClient({
        //     apiKey: process.env.NEXT_PUBLIC_GETSTREAM_API_KEY as string,
        //     user: userGetStream,
        //     token: chatToken,
        //   }),
        // );
      }
      setChatClient(streamChatFrontend);
      setLastConnectionStatus(status);
    };

    const disconnect = async () => {
      setChatClient(null);
      await chatClient?.disconnectUser();
    };

    const handler = async () => {
      const currentConnectionStatus: ConnectionStatus = !chatToken
        ? 'Anonymous'
        : 'Authenticated';

      if (currentConnectionStatus !== lastConnectionStatus) {
        if (lastConnectionStatus !== 'None') {
          await disconnect();
        }
        await connect(currentConnectionStatus);
      }
      setIsLoading(false);
    };

    if (userDataFull !== undefined) {
      handler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chatToken,
    userDataFull?.id,
    userDataFull?.imgUrl,
    userDataFull?.username,
    userDataFull?.isAnonymous,
    lastConnectionStatus,
  ]);

  return (
    <StreamContext.Provider value={{ chatClient, isLoading }}>
      {children}
    </StreamContext.Provider>
  );
});
