import { toFraction } from '../../../functions/src/util/ads/toFraction';
import { AD_UNIT_IDS } from '../../config/ads/personaAdUnitIds';

export function findClosestPersonaAspectRatio(
  width: number,
  height: number,
  adjustable?: 'width' | 'height',
) {
  const targetAspectRatio = toFraction(`${width}:${height}`);

  const closest = Object.entries(AD_UNIT_IDS)
    .map(([aspectRatio, adUnitId]) => {
      const [adWidth, adHeight] = aspectRatio.split('x').map(Number);
      return {
        aspectRatio,
        adUnitId,
        difference: Math.abs(
          toFraction(`${adWidth}:${adHeight}`) - targetAspectRatio,
        ),
      };
    })
    .reduce((closest, current) => {
      return closest.difference < current.difference ? closest : current;
    });

  let adjustedWidth = width;
  let adjustedHeight = height;

  if (adjustable === 'height') {
    const [adWidth, adHeight] = closest.aspectRatio.split('x').map(Number);
    adjustedHeight = Math.round(width * (adHeight / adWidth));
  } else if (adjustable === 'width') {
    const [adWidth, adHeight] = closest.aspectRatio.split('x').map(Number);
    adjustedWidth = Math.round(height * (adWidth / adHeight));
  }

  return {
    width: adjustedWidth,
    height: adjustedHeight,
    adUnitId: closest.adUnitId,
    aspectRatio: closest.aspectRatio,
  };
}
