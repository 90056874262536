import { Timestamp } from '../../../../config/firebaseAdmin';
import { Competition } from '../Competition';
import { Subscribable } from '../../../Subscribable';
import { WithAdditionalImages } from '../../../MoreImages';
import { WaitlistTeam } from './Waitlist';
import { GuestlistTeam } from './Guestlist';
import { ParticipantTeam } from './Participant';
import { Contributable } from '../../PrizePool';
import { Identifiable } from '../../../Identifiable';
import { Resolve } from '../../../utility-types';
import { Roled } from '../../../Roled';
import { AllSignInMethod } from '../../User';

export const DEFAULT_SQUARE_IMG = '/assets/images/blumint_squareImg.png';
export const DEFAULT_CALENDAR_IMG =
  '/assets/images/tournament-calendar/img-url-default.png';

export type HeatEndpoint = {
  url: string;
  pollLevel: 'heat' | 'result';
  method: 'GET' | 'POST';
  postBody?: Record<string, unknown>;
};

export type QualificationConditions =
  | { type: 'proportion'; proportion: number }
  | { type: 'top'; numberOfPlaces: number };

export const BRACKET_TYPE = [
  'single-elimination',
  'double-elimination',
  'heats',
  'no-bracket',
] as const;

/**
 * @remarks
 * whenever we add a new bracket type on here please add it to src/util/tournaments/toFormInput.ts under the Bracket Type Field */
export type BracketType = typeof BRACKET_TYPE[number];

export const BUFFER_TIME_DEFAULT = 300000;

export const TOURNAMENT_PHASE = [
  'unpublished',
  'review',
  'registration',
  'checkIn',
  'ready',
  'live',
  'finished',
  'payout',
] as const;

export const TOURNAMENT_PHASE_PUBLISHED = [
  'registration',
  'checkIn',
  'ready',
  'live',
  'finished',
  'payout',
] as const;

export const TOURNAMENT_PHASE_EDITABLE = [
  'unpublished',
  'review',
  'registration',
] as const;

export const TOURNAMENT_PHASE_BEFORE_REGISTRATION = [
  'unpublished',
  'review',
] as const;

export const TOURNAMENT_PHASE_PAST = ['finished', 'payout'] as const;

export const TOURNAMENT_PHASE_PRESENT = ['checkIn', 'ready', 'live'] as const;

export type TournamentPhase = typeof TOURNAMENT_PHASE[number];

export type TournamentPhasePublished =
  typeof TOURNAMENT_PHASE_PUBLISHED[number];

export type TournamentPhaseEditable = typeof TOURNAMENT_PHASE_EDITABLE[number];

export type TournamentPhasePast = typeof TOURNAMENT_PHASE_PAST[number];

export type TournamentPhasePresent = typeof TOURNAMENT_PHASE_PRESENT[number];

export type TournamentPhaseBeforeRegistration =
  typeof TOURNAMENT_PHASE_BEFORE_REGISTRATION[number];

export type Waitlistable = { waitlisted: boolean; wasWaitlisted?: boolean };

export type Solo<T = Timestamp> = Waitlistable & {
  registrationTime: T;
  userId: string;
  username: string;
  imgUrl: string;
  checkedIn: boolean;
  inGameId?: string;
  telegramId?: string;
  discordId?: string;
  solanaWalletAddress?: string;
};
export type TournamentDetail = {
  title: string;
  body: string;
  id?: string;
};

/**
 * @remarks
 * N.B. except for concurrentMatches, these should all be in ms
 */
export type TournamentTimingParams = {
  /**
   * @remarks
   * in milliseconds
   */
  maxMatchLength: number;
  /**
   * @remarks
   * in milliseconds
   */
  maxMatchDelay: number;
  /**
   * @remarks
   * in milliseconds
   */
  bufferTime: number;
  concurrentMatches: number;
  /**
   * @remarks
   * in milliseconds
   */
  roundGap: number;
};

export type TournamentAggregations<T = Timestamp> = {
  waitlistAggregated: WaitlistTeam<T>[];
  guestlistAggregated: GuestlistTeam<T>[];
  participantsAggregated: ParticipantTeam<T>[];
};

export type RegistrationOptionBase = Identifiable & {
  preamble: string;
  postambleText?: string;
  postambleLink?: string;
  optional: boolean;
};
export type RegistrationOptionCustom = RegistrationOptionBase & {
  formatExample: string;
  regex: string;
  regexExplained?: string;
  name: string;
};

export type RegistrationOptionAuto = RegistrationOptionBase & {
  providers: AllSignInMethod[] | 'fetched';
};

export type RegistrationOption =
  | RegistrationOptionCustom
  | RegistrationOptionAuto;

export type Tournament<T = Timestamp> = Resolve<
  WithAdditionalImages<
    Subscribable<
      Competition<T> & {
        type: 'tournament';
        region?: string;
        bracketType: BracketType;
        // TODO: Remove
        codHeats?: boolean;
        maxTeamSize: number;
        maxTeamCount: number;
        /**
         * @remarks
         * When minTeamSize is undefined, it defaults to maxTeamSize
         */
        minTeamSize?: number;
        phase: TournamentPhase;
        tournamentDetails: TournamentDetail[];
        checkInTime: T;
        readyTime: T;
        adminIds: string[];
        qualification?: QualificationConditions[];
        registrationOptions: RegistrationOption[];
        squareImgUrl?: string;
        /**
         * @remarks
         * first heat at index 0, second heat at index 1, etc..
         * not to be confused with Competition['endpoint']
         */
        endpoints?: HeatEndpoint[]; //TODO: HeatEndpoint[][]
        skipCheckIn?: boolean;
        continuousRegistration?: boolean;
        requirementTokens?: Record<
          string,
          { collectionName: string; templateId: string; count: number }[]
        >;
        /**
         * @remarks
         * This is set to string | null to allow proper querying
         */
        prizePoolId: string | null;
        matchSettings?: Record<string, string | number>;
        bestOf: number;
        tiebreakSettings?: Record<string, string | number>;
        preventMerging: boolean;
        livestreamId?: string;
        /**
         * @remarks
         * The last element in the playbackIds corresponds to the active livestream
         */
        playbackIds?: string[];
      } & TournamentTimingParams &
        TournamentAggregations<T> &
        Contributable &
        Roled<'contributor' | 'moderator' | 'streamer'>
    >,
    'profileImgUrl' | 'streamerLiveThumbnail'
  >
>;
